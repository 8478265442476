<template>
  <CopsMatches v-if="game === 'c-ops'"></CopsMatches>
</template>

<script>
import CopsMatches from "./c-ops/matches";
export default {
  components: {
    CopsMatches,
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
  },
};
</script>