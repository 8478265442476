<template>
  <transition name="fade" mode="out-in">
    <section class="tab" :class="tab.name" :key="tab.name">
      <slot :name="tab.name"></slot>
    </section>
  </transition>
</template>

<script>
export default {
  props: {
    value: String,
    tabs: Array,
  },
  computed: {
    tab() {
        return this.tabs.find(tab => tab.name === this.value);
    }
  }
};
</script>