<template>
  <input
    v-if="!prompt"
    class="l-input" 
    :value="value"
    :type="type" 
    :disabled="disabled"
    :class="{ grow, disabled, prompt }"
    @change="onChange"
    @click="onClick"
    :style="{ 'max-width': maxWidth }"
  />
  <div
    v-else
    class="l-input prompt"
    :class="{ grow, disabled }"
    @click="onClick"
    :style="{ 'max-width': maxWidth }"
    v-text="value"
  >
  </div>
</template>

<script>
export default {
  data: () => ({
    maxWidth: '',
  }),
  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    grow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: Boolean,
      default: false
    },
    maxwidth: {
      type: String,
    },
  },
  created() {
    if (this.prompt && !this.maxwidth) this.maxWidth = "100px";
    else if (this.maxwidth) this.maxWidth = this.maxwidth;
    else this.maxWidth = "unset";
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>