<template>
  <div id="body">
    <header class="mobile" :class="!header ? 'hidden' : ''">
      <!--<game-select :game="game" :games="games" @setGame="setGame"></game-select>-->
      <game-select></game-select>
      <div class="other" v-if="game">
        <router-link :to="`/${game}/search`">
          <i class="fa-solid fa-magnifying-glass fa-lg"></i>
        </router-link>
        <router-link to="/settings" v-if="!user">
          <i class="fa-solid fa-sliders fa-lg"></i>
        </router-link>
        <router-link :to="user ? '/profile' : '/login'" class="profile">
          <i
            :class="
              user
                ? 'fa-regular fa-user fa-lg'
                : 'fa-solid fa-right-to-bracket fa-lg'
            "
          ></i>
        </router-link>
      </div>
    </header>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <nav v-if="game && footer">
      <div class="wrap">
        <router-link :to="`/${game}/news`">
          <i class="fa-solid fa-newspaper fa-2x"></i>
        </router-link>
        <router-link :to="`/${game}/matches`">
          <i class="fa-solid fa-medal fa-2x"></i>
        </router-link>
        <router-link :to="`/${game}/events`">
          <i class="fa-solid fa-calendar-day fa-2x"></i>
        </router-link>
        <!--<router-link :to="`/${game}/rankings`">
          <i class="fa-solid fa-chart-line fa-2x"></i>
        </router-link>-->
      </div>
      <!--<a class="special">
        <i class="ri-trophy-line"></i>
      </a>-->
    </nav>
    <notifications position="top left" width="100%">
      <template slot="body" slot-scope="{ item, close }">
        <div class="vue-notification" :class="item.type">
          <div class="text">
            <p class="title">
              {{ item.title }}
            </p>
            <div v-html="item.text"/>
          </div>
          <button class="close" @click="close">
            <i class="fa-solid fa-times"></i>
          </button>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import GameSelect from "./components/GameSelect.vue";
import moment from "moment-timezone";
export default {
  name: "App",
  components: {
    GameSelect,
  },
  data: () => ({
    header: true,
    footer: true,
  }),
  computed: {
    game() {
      return this.$store.state.game;
    },
    breakpoint() {
      return window.innerWidth;
    },
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    //Set the initial locale that is loaded from localStorage by VuexPersist
    this.$i18n.locale = this.$store.state.locale;
    //Set the timezone to guess the timezone from the browser if autoTimezone is true
    if (this.$store.state.autoTimezone) {
      this.$store.commit("setTimezone", moment.tz.guess());
    }
    //Get the user from the server
    this.$getUser();
  },
  methods: {
    log(e) {
      console.log(e);
    },
    async checkRoute(to, from) {
      from;
      //console.log(to);
      if (from && (to.name === from.name)) return;

      if (from && to.meta.saveRedirect && !from.meta.dontRedirectHere && !this.$store.state.redirectingBack)
        this.$store.commit('setRedirectPath', from.fullPath);

      if (this.$store.state.redirectingBack) this.$store.commit('setRedirectingBack', false);
      
      if (to.path === "/" && to.name !== null) {
        console.log("Redirecting to game page");
        let game = this.$store.state.game;
        if (game) {
          this.$router.push(`/${game}/news`);
        } else {
          this.$store.commit("setGame", "c-ops");
          this.$router.push(`/${this.$store.state.game}/news`);
        }
      } else if (to.params.game) {
        console.log("Setting game");
        this.$store.commit("setGame", to.params.game);
      }

      //if (from && (to.name !== from.name)) this.$getUser();
      this.header = to.meta.header === undefined ? true : to.meta.header;
      this.footer = to.meta.footer === undefined ? true : to.meta.footer;
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        //console.log(to);
        this.checkRoute(to, from);
      },
      immediate: true,
    },
  },
};
</script>