export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/user/auth/Login.vue"),
    meta: {
      header: false,
      footer: false,
      saveRedirect: true
    }
  },
  {
    path: "/callback/:platform",
    name: "Callback",
    component: () => import("../views/user/auth/Callback.vue"),
    meta: {
      header: false,
      footer: false,
      dontRedirectHere: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/user/Profile.vue"),
    meta: {
      header: false,
      footer: false,
      saveRedirect: true
    }
  },
  {
    path: "/register-success",
    name: "RegisterSuccess",
    component: () => import("../views/user/auth/RegisterSuccess.vue"),
    meta: {
      header: false,
      footer: false,
      dontRedirectHere: true
    }
  },
  {
    path: "/verify-email/:token",
    name: "VerifyEmail",
    component: () => import("../views/user/auth/VerifyEmail.vue"),
    meta: {
      header: false,
      footer: false,
      dontRedirectHere: true
    }
  },
  {
    path: "/account-recovery",
    name: "AccountRecovery",
    component: () => import("../views/user/auth/AccountRecovery.vue"),
    meta: {
      header: false,
      footer: false,
      dontRedirectHere: true
    }
  },
  {
    path: "/password-reset/:token",
    name: "PasswordReset",
    component: () => import("../views/user/auth/PasswordReset.vue"),
    meta: {
      header: false,
      footer: false,
      dontRedirectHere: true
    }
  },
  {
    path: "/settings",
    component: () => import("../views/user/settings/_.vue"),
    children: [
      {
        path: "",
        name: "Settings",
        component: () => import("../views/user/settings/_Sections.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "general",
        name: "App Settings",
        component: () => import("../views/user/settings/General.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "account",
        name: "Account Settings",
        component: () => import("../views/user/settings/Account.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "connections",
        name: "Connections",
        component: () => import("../views/user/settings/Connections.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import("../views/user/settings/Notifications.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "privacy",
        name: "Privacy",
        component: () => import("../views/user/settings/Privacy.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      },
      {
        path: "security",
        name: "Security",
        component: () => import("../views/user/settings/Security.vue"),
        meta: {
          header: false,
          footer: false,
          saveRedirect: true
        }
      }
    ],
    
  }
];