export default {
  // Auth and User states
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserLoading(state, loading) {
    state.userLoading = loading;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
  setAutoTimezone(state, autoTimezone) {
    state.autoTimezone = autoTimezone;
  },
  setTimezone(state, timezone) {
    state.timezone = timezone;
  },

  // App states
  setRankingTab(state, tab) {
    state.rankingTab = tab;
  },
  setRankingRegion(state, region) {
    state.rankingRegion = region;
  },
  setNewsTab(state, tab) {
    state.newsTab = tab;
  },
  setGame(state, game) {
    state.game = game;
  },
  setRedirectPath(state, path) {
    state.redirectPaths.push(path);
  },
  setRedirectingBack(state, redirecting) {
    state.redirectingBack = redirecting;
  },
  removeLatestRedirectPath(state) {
    state.redirectPaths.pop();
  },

  // App data
  setNews(state, news) {
    state.news = news;
  },
  setArticles(state, articles) {
    state.articles = articles;
  },
  setMatches(state, matches) {
    state.matches = matches;
  },
  setEvents(state, events) {
    state.events = events;
  },
}