var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"cops-match-preview",attrs:{"to":{ name: 'Match', params: { game: _vm.game, match: _vm.match._id } }}},[_c('div',{staticClass:"event"},[_c('span',[_vm._v(_vm._s(_vm.match.event.name))]),_c('span',[_vm._v(_vm._s(_vm.match.data.title))])]),_c('div',{staticClass:"match-info"},[_c('div',{staticClass:"team-logo"},[_c('img',{attrs:{"src":_vm.$cdn('team-logo', _vm.match.teams[0]),"alt":_vm.match.teams[0].name}})]),(_vm.match.started || _vm.match.ended)?_c('div',{staticClass:"data",class:{ ended: _vm.match.ended }},[_c('div',{staticClass:"data-item"},[_c('span',{staticClass:"data-item-label"},[_vm._v("Best of:")]),_c('span',{staticClass:"data-item-value"},[_vm._v(_vm._s(_vm.match.data.bo))])]),_c('div',{staticClass:"data-item score"},[(_vm.match.data.bo === 1)?[_c('p',{class:{ 
            loser: _vm.match.maps[0].score[_vm.team1] < _vm.match.maps[0].score[_vm.team2], 
            winner: _vm.match.maps[0].score[_vm.team1] > _vm.match.maps[0].score[_vm.team2]
          }},[_vm._v(_vm._s(_vm.match.maps[0].score[_vm.team1]))]),_c('span',[_vm._v("-")]),_c('p',{class:{ 
            loser: _vm.match.maps[0].score[_vm.team2] < _vm.match.maps[0].score[_vm.team1], 
            winner: _vm.match.maps[0].score[_vm.team2] > _vm.match.maps[0].score[_vm.team1] 
          }},[_vm._v(_vm._s(_vm.match.maps[0].score[_vm.team2]))])]:_vm._e(),(_vm.match.data.bo > 1)?[_c('p',{class:{
            loser: _vm.boScore[_vm.team1] < _vm.boScore[_vm.team2],
            winner: _vm.boScore[_vm.team1] > _vm.boScore[_vm.team2]
          }},[_vm._v(_vm._s(_vm.boScore[_vm.team1]))]),_c('span',[_vm._v("-")]),_c('p',{class:{
            loser: _vm.boScore[_vm.team2] < _vm.boScore[_vm.team1],
            winner: _vm.boScore[_vm.team2] > _vm.boScore[_vm.team1]
          }},[_vm._v(_vm._s(_vm.boScore[_vm.team2]))])]:_vm._e()],2)]):_c('div',{staticClass:"data"},[_c('div',{staticClass:"data-item score"},[_vm._v(" "+_vm._s(_vm.startTime.time)+" ")]),_c('div',{staticClass:"data-item"},[_c('span',{staticClass:"data-item-label"},[_vm._v(" "+_vm._s(_vm.startTime.date)+" ")])])]),_c('div',{staticClass:"team-logo"},[(_vm.team2)?_c('img',{attrs:{"src":_vm.$cdn('team-logo', _vm.match.teams[1]),"alt":_vm.match.teams[1].name}}):_vm._e()])]),_c('div',{staticClass:"team-names"},[_c('span',[_vm._v(_vm._s(_vm.match.teams[0].name))]),(_vm.team2)?_c('span',[_vm._v(_vm._s(_vm.match.teams[1].name))]):_c('span',[_vm._v("-")])])])}
var staticRenderFns = []

export { render, staticRenderFns }