<template>
  <main class="news">
    <h1>News</h1>
    <Tabs v-model="tab" :tabs="tabs"></Tabs>
    <TabItems v-model="tab" :tabs="tabs">
      <template slot="news">
        <l-loading v-model="news.length">
          <div class="content">
            <article v-for="n in news" :key="n._id">
              <div class="text">
                <span class="text" v-html="n.text"></span>
                <span class="when" v-html="n.when"></span>
              </div>
              <div class="links">
                <router-link
                  class="link"
                  v-for="(l, i) in n.links"
                  :key="`${n._id}-l-${i}`"
                  :to="l.link"
                >
                  <i :class="newsLinkIcon(l.type)"></i>
                  <span v-html="l.text"></span>
                </router-link>
              </div>
            </article>
          </div>
        </l-loading>
      </template>
      <template slot="articles">
        <l-loading v-model="articles.length">
          <div class="content">
            <article v-for="a in articles" :key="a._id">
              <div class="banner" :style="{ 'background-image': `url(${$cdn('article-banner', a)})` }">
                <div class="details">
                  <span class="when">{{a.when}}</span>
                  <div class="length">
                    <i class="ri-time-line"></i>
                    <span>{{a.length}} minute read</span>
                  </div>
                </div>
              </div>
              <div class="text">
                <span class="text"
                  >{{a.title}}</span
                >
                <router-link :to="`/article/${a._id}`">READ</router-link>
              </div>
            </article>
          </div>
        </l-loading>
      </template>
    </TabItems>
  </main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    tab: "news",
    tabs: [
      {
        name: "news",
        label: "News",
      },
      {
        name: "articles",
        label: "Articles",
      },
    ],
    news: [],
    articles: [],
  }),
  methods: {
    newsLinkIcon(type) {
      switch (type) {
        case "team":
          //return "ri-group-fill";
          return "fa-solid fa-users fa-xs";
        case "player":
          return "fa-solid fa-image-portrait fa-xs";
      }
    },
  },
  watch: {
    tab: {
      handler(tab) {
        if (tab === "news") {
          axios.get(this.$api("/news")).then((res) => {
            let data = res.data.docs;
            for (let i = 0; i < data.length; i++) {
              data[i].when = moment(data[i].createdAt).fromNow();
            }
            this.news = data;
          });
        } else if (tab === "articles") {
          axios.get(this.$api("/articles")).then((res) => {
            let data = res.data.docs;
            for (let i = 0; i < data.length; i++) {
              data[i].when = moment(data[i].createdAt).fromNow();
            }
            this.articles = data;
          });
        }
      },
      immediate: true,
    }
  }
};
</script>