<template>
  <section class="tabs-wrap">
    <div class="tabs" :class="{ fit }">
      <button
        v-for="tab in tabs"
        :key="tab.label"
        v-html="tab.label"
        :class="{ active: value === tab.name }"
        :ref="'tab-' + tab.name"
        @click="selectTab(tab.name)"
      ></button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabs: Array,
    value: String,
    fit: Boolean
  },
  methods: {
    selectTab(tab) {
      const tabEl = this.$refs[`tab-${tab}`][0];
      tabEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      this.$emit("input", tab);
      this.$emit("change", tab);
    },
  },
};
</script>

<style>
</style>