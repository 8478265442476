<template>
  <transition name="fade" mode="out-in">
    <slot v-if="value"></slot>
    <div v-else key="loading" class="l-loading">
      <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["value"],
  created() {
    //console.log(this.value);
  }
}
</script>

<style>

</style>