import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import axios from 'axios';
import moment from 'moment';
import config from "../config";

import ISOcountries from "i18n-iso-countries";
ISOcountries.registerLocale(require("./i18n/countryLocales/en.json"));
ISOcountries.registerLocale(require("./i18n/countryLocales/cs.json"));

Vue.config.productionTip = false;

Vue.prototype.$api = (url) => config.apiURL + url;
Vue.prototype.$cdn = (url) => config.cdnURL + url;
Vue.prototype.$cdn = (type, data) => {
  switch (type) {
    case "team-logo":
      return `${config.cdnURL}/team/${data._id}/logo/${data.logo}`;
    case "event-logo":
      return `${config.cdnURL}/event/${data._id}/logo/${data.logo}`;
    case "game-map-banner":
      return `${config.cdnURL}/game/${data.game}/maps/banner/${data.map}`;
    case "article-banner":
      return `${config.cdnURL}/article/${data._id}/banner`;
    default:
      return ""
  }
}

//import "./assets/scss/app.scss";

//#region Presets
let games = require("@/assets/presets/games.js");
Vue.prototype.$games = games;
Vue.prototype.$platforms = [
  {
    name: "discord",
    title: "Discord",
    icon: "fa-brands fa-discord fa-xl",
    color: "#5865F2",
    fgColor: "#FFFFFF",
    comingSoon: false
  }/*,
  {
    name: "twitch",
    title: "Twitch",
    icon: "fa-brands fa-twitch fa-xl",
    color: "#6441A5",
    fgColor: "#FFFFFF",
    comingSoon: true
  }*/
];
//#endregion

//#region Methods
Vue.prototype.$back = () => {
  if (store.state.redirectPaths.length > 0) {
    router.push(store.state.redirectPaths[store.state.redirectPaths.length - 1]);
    store.commit("removeLatestRedirectPath");
    store.commit("setRedirectingBack", true);
  } else {
    router.push("/");
  }
};
Vue.prototype.$getRegionIcon = (region) => {
  switch (region) {
    case "eu":
      return "/icons/flags/eu.svg";
    case "na":
      return "/icons/flags/us.svg";
    case "sa":
      return "/icons/flags/br.svg";
    case "as":
      return "/icons/flags/cn.svg";
  }
}
Vue.prototype.$getMonthYear = (date) => {
  return moment(date).format("MMMM YYYY");
}
Vue.prototype.$getDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
}
Vue.prototype.$getDateBetter = (date) => {
  return moment(date).format("DD MMM YYYY");
}
Vue.prototype.$getCountryName = (code) => {
  let locale = store.state.locale;
  if (typeof code === "object") code = code.code;
  return ISOcountries.getName(code, locale);
}
//#endregion

//#region User Auth

Vue.prototype.$getUser = () => {
  if (store.state.token) {
    store.commit('setUserLoading', true);
    axios.get(Vue.prototype.$api("/user"), {
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }).then(res => {
      store.commit('setUser', res.data);
      store.commit('setUserLoading', false);
      console.log("User data loaded", res.data);
    }).catch(error => {
      console.log(error);
      store.commit('setToken', null);
      store.commit('setUser', null);
      store.commit('setUserLoading', false);
    });
  }
};

//#endregion


//#region Components

import Tabs from "./components/Tabs.vue";
import TabItems from "./components/TabItems.vue";
import PageHeader from '@/components/PageHeader.vue';
import Select from '@/components/Select.vue';
import Switch from '@/components/Switch.vue';
import Input from '@/components/Input.vue';
import LPrompt from '@/components/LPrompt.vue';
import Loading from '@/components/Loading.vue';


Vue.component('Tabs', Tabs);
Vue.component('TabItems', TabItems);
Vue.component('PageHeader', PageHeader);
Vue.component('l-select', Select);
Vue.component('l-switch', Switch);
Vue.component('l-input', Input);
Vue.component('l-prompt', LPrompt);
Vue.component('l-loading', Loading);


import Notifications from 'vue-notification'
Vue.use(Notifications);

//#endregion


/*router.beforeEach((to, from, next) => {
  if (to.meta.saveRedirect) {
    if (from.meta.dontRedirectHere) next();
    store.commit('setRedirectPath', from.fullPath);
  }
  next();
});*/

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
