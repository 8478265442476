<template>
  <div class="l-modal-wrapper" :class="{ 'hidden': !value, bottom }">
    <div class="l-modal-close" @click="close"></div>
    <div class="l-modal">
      <div class="l-modal-header">
        <i :class="icon"></i>
        <span class="title">{{ title }}</span>
        <i class="close fa-solid fa-times fa-lg" @click="close"></i>
      </div>
      <div class="l-modal-body">
        <slot name="body"></slot>
      </div>
      <div class="l-modal-footer">
        <slot name="actions">
          <button class="cancel" @click="close">{{ cancelText }}</button>
          <button class="ok" @click="ok">{{ okText }}</button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'input'
    },
    title: {
      type: String,
      default: 'Modal'
    },
    content: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    okText: {
      type: String,
      default: 'Ok'
    }
  },
  data: () => ({
    input: '',
  }),
  computed: {
    icon() {
      switch (this.type) {
        case 'input':
          return 'fa-solid fa-keyboard fa-lg';
        case 'prompt':
          return 'fa-solid fa-question-circle fa-lg';
        default:
          return 'fa-solid fa-keyboard fa-lg';
      }
    }
  },
  created() {
    this.input = this.content;
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    ok() {
      this.$emit('ok', this.input);
      this.close();
    }
  }
}
</script>