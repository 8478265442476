<template>
  <main class="matches">
    <h1 class="reloadable">
      Matches
      <button class="reload" @click="reload">
        <i class="fas fa-sync-alt"></i>
      </button>
    </h1>
    <Tabs v-model="tab" :tabs="tabs" @change="tabClick"></Tabs>
    <TabItems v-model="tab" :tabs="tabs">
      <template v-if="!loading" slot="live">
        <template v-if="liveMatches.length > 0">
          <div class="panel header"><span></span> Live matches</div>
          <CopsMatchPreview state="live" v-for="match in liveMatches" :key="match.id" :match="match"></CopsMatchPreview>
        </template>
        <span class="no-content" v-else>No current ongoing matches.</span>
      </template>
      <template v-else slot="live">
        <div class="l-loading">
          <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </template>
      <template v-if="!loading" slot="upcoming">
        <template v-if="upcomingMatches.length > 0">
          <CopsMatchPreview state="upcoming" v-for="match in upcomingMatches" :key="match.id" :match="match"></CopsMatchPreview>
        </template>
        <span class="no-content" v-else>No current upcoming matches.</span>
      </template>
      <template v-else slot="upcoming">
        <div class="l-loading">
          <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </template>
      <template v-if="!loading" slot="results">
        <template v-if="results.length > 0">
          <CopsMatchPreview state="results" v-for="match in results" :key="match.id" :match="match"></CopsMatchPreview>
        </template>
        <span class="no-content" v-else>No recent results.</span>
        <div class="page-actions">
          <router-link class="button" :to="`/c-ops/matches/results`">View all results</router-link>
        </div>
      </template>
      <template v-else slot="results">
        <div class="l-loading">
          <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </template>
    </TabItems>
  </main>
</template>

<script>
import axios from 'axios';

import CopsMatchPreview from "@/components/c-ops/copsMatchPreview.vue";

export default {
  name: "copsMatches",
  components: {
    CopsMatchPreview
  },
  data: () => ({
    tab: "live",
    tabs: [
      {
        name: "live",
        label: "Live",
      },
      {
        name: "upcoming",
        label: "Upcoming",
      },
      {
        name: "results",
        label: "Results",
      },
    ],
  }),
  computed: {
    game() {
      return this.$store.state.game;
    },
    loading() {
      return this.$store.state.matches === null;
    },
    liveMatches() {
      return this.$store.state.matches.live;
    },
    upcomingMatches() {
      return this.$store.state.matches.upcoming;
    },
    results() {
      return this.$store.state.matches.results;
    },
  },
  mounted() {
    if (this.loading) {
      this.getMatches();
    }
  },
  methods: {
    tabClick(tab) {
      //console.log("tab changed");
      this.$router.push({ query: { tab } });
    },
    getMatches() {
      axios.get(this.$api('/matches'))
      .then(res => {
        this.$store.commit('setMatches', res.data);
      })
      .catch(err => {
        alert(err);
      });
    },
    reload() {
      this.$store.commit("setMatches", null);
      this.getMatches();
    }
  },
  watch: {
    $route: {
      handler(route) {
        if (!route.query.tab) {
          if (this.liveMatches.length === 0) {
            this.tab = "upcoming";
          } else {
            this.tab = "live";
          }
        } else {
          this.tab = route.query.tab;
        }
      },
      immediate: true,
    },
  },
};
</script>