import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';
import axios from 'axios';
import i18n from '../i18n';


Vue.use(Vuex)

import mutations from './mutations';

export default new Vuex.Store({
  state: {
    // Auth and User states
    token: null,
    user: null,
    userLoading: false,
    locale: "en",
    autoTimezone: true,
    timezone: null,
    // App states
    redirectPaths: [],
    redirectingBack: false,
    rankingTab: "teams",
    rankingRegion: "global",
    newsTab: "feed",
    game: null,
    // App data
    news: null,
    articles: null,
    matches: null,
    events: null,
  },
  mutations,
  actions: {
    logout(store) {
      store.commit('setToken', null);
      store.commit('setUser', null);
    },
    updateUser(store) {
      axios.get(Vue.prototype.$api('/user'), {
        headers: {
          Authorization: 'Bearer ' + store.state.token
        }
      })
      .then(response => {
        store.commit('setUser', response.data);
      })
      .catch(error => {
        console.log(error);
      });
    },
    setLocale(store, locale) {
      store.commit('setLocale', locale);
      i18n.locale = locale;
    }
  },
  modules: {
  },
  plugins: [
    new VuexPersist({
      key: 'biscuit',
      reducer: (state) => ({
        token: state.token,
        game: state.game,
        redirectPaths: state.redirectPaths,
        locale: state.locale,
        autoTimezone: state.autoTimezone,
        timezone: state.timezone,
      })
    }).plugin
  ]
});