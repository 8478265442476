import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from './locales/en.json';
import cs from './locales/cs.json';

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en, cs
  }
});