import Vue from 'vue'
import VueRouter from 'vue-router'
import GameNews from '../views/News.vue'
import GameMatches from '../views/Matches.vue'
import GameEvents from '../views/Events.vue'

Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    name: "Home",
    component: () => import('../views/Home.vue'),
    meta: {
      header: false,
      footer: false
    }
  },
  {
    path: "/:game/news",
    name: "News",
    component: GameNews
  },
  {
    path: "/article/:article",
    name: "Article",
    component: () => import('../views/Article.vue'),
    meta: {
      header: false,
      footer: false,
      saveRedirect: true
    }
  },
  {
    path: "/:game/matches",
    name: "Matches",
    component: GameMatches,
  },
  {
    path: "/:game/matches/results",
    name: "MatchesResults",
    component: () => import('../views/MatchesResults.vue'),
    meta: {
      header: false,
      saveRedirect: true
    }
  },
  {
    path: "/:game/events",
    name: "Events",
    component: GameEvents
  },
  {
    path: "/:game/rankings",
    name: "Rankings",
    component: () => import('../views/Rankings.vue')
  },
  {
    path: "/:game/search",
    name: "Search",
    component: () => import('../views/Search.vue')
  },
  {
    path: "/:game/player/:player",
    name: "Player",
    component: () => import('../views/Player.vue'),
    meta: { 
      header: false,
      footer: true,
      saveRedirect: true
    }
  },
  {
    path: "/:game/match/:match",
    name: "Match",
    component: () => import('../views/Match.vue'),
    meta: { 
      header: false,
      footer: false,
      saveRedirect: true
    }
  },
  {
    path: "/:game/team/:team",
    name: "Team",
    component: () => import('../views/Team.vue'),
    meta: { 
      header: false,
      footer: true,
      saveRedirect: true
    }
  }
];

import userRoutes from './userRoutes';
routes.push(...userRoutes);

routes.push({
  path: '*',
  name: '404',
  component: () => import('../views/404.vue')
})

const router = new VueRouter({
  router,
  mode: 'history',
  routes
})

export default router