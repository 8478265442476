<template>
  <div class="l-switch" @click="change">
    <div class="l-switch-item" :class="{ 'active': !value }">
      {{ off }}
    </div>
    <div class="l-switch-item" :class="{ 'active': value }">
      {{ on }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    off: {
      type: String,
      default: ""
    },
    on: {
      type: String,
      default: ""
    },
  },
  computed: {
    text() {
      return this.value ? this.on : this.off;
    }
  },
  methods: {
    change() {
      this.$emit('input', !this.value);   
    } 
  }
}
</script>