<template>
  <router-link class="cops-match-preview" :to="{ name: 'Match', params: { game, match: match._id } }">
    <div class="event">
      <span>{{ match.event.name }}</span>
      <span>{{ match.data.title }}</span>
    </div>
    <div class="match-info">
      <div class="team-logo">
        <img :src="$cdn('team-logo', match.teams[0])" :alt="match.teams[0].name" />
      </div>
      <div v-if="match.started || match.ended" class="data" :class="{ ended: match.ended }">
        <div class="data-item">
          <span class="data-item-label">Best of:</span>
          <span class="data-item-value">{{ match.data.bo }}</span>
        </div>
        <div class="data-item score">
          <template v-if="match.data.bo === 1">
            <p :class="{ 
              loser: match.maps[0].score[team1] < match.maps[0].score[team2], 
              winner: match.maps[0].score[team1] > match.maps[0].score[team2]
            }">{{ match.maps[0].score[team1] }}</p>
            <span>-</span>
            <p :class="{ 
              loser: match.maps[0].score[team2] < match.maps[0].score[team1], 
              winner: match.maps[0].score[team2] > match.maps[0].score[team1] 
            }">{{ match.maps[0].score[team2] }}</p>
          </template>
          <template v-if="match.data.bo > 1">
            <p :class="{
              loser: boScore[team1] < boScore[team2],
              winner: boScore[team1] > boScore[team2]
            }">{{ boScore[team1] }}</p>
            <span>-</span>
            <p :class="{
              loser: boScore[team2] < boScore[team1],
              winner: boScore[team2] > boScore[team1]
            }">{{ boScore[team2] }}</p>
          </template>
        </div>
      </div>
      <div v-else class="data">
        <div class="data-item score">
          {{ startTime.time }}
        </div>
        <div class="data-item">
          <span class="data-item-label">
            {{ startTime.date }}
          </span>
        </div>
      </div>
      <div class="team-logo">
        <img v-if="team2" :src="$cdn('team-logo', match.teams[1])" :alt="match.teams[1].name" />
      </div>
    </div>
    <div class="team-names">
      <span>{{ match.teams[0].name }}</span>
      <span v-if="team2">{{ match.teams[1].name }}</span>
      <span v-else>-</span>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
    team1() {
      return this.match.teams[0]._id;
    },
    team2() {
      if (this.match.teams[1]) {
        return this.match.teams[1]._id;
      }
      else return null;
    },
    boScore() {
      let score =  this.match.maps.reduce((acc, curr) => {
        return {
          [this.team1]: curr.winner === this.team1 ? acc[this.team1] + 1 : acc[this.team1],
          [this.team2]: curr.winner === this.team2 ? acc[this.team2] + 1 : acc[this.team2],
        };
      }, {
        [this.team1]: 0,
        [this.team2]: 0,
      });
      console.log(score);
      return score;
    },
    startTime() {
      let time = moment(this.match.start);
      return {
        time: time.format("HH:mm"),
        date: time.format("DD.MM.YYYY"),
      };
    },
  },
}
</script>

<style>

</style>