<template>
  <main class="events">
    <h1>Events</h1>
    <section>
      <div class="section-head">
        <h2>Featured</h2>
      </div>
      <div class="content our-picks">
        <article
          class="our-pick"
          style="
            background-image: url('/imgs/events/circuit-s3/main_banner.jpeg');
          "
        >
          <div class="darken"></div>
          <span class="title">Circuit S3</span>
          <span class="date-span">08/21 - 11/21</span>
        </article>
        <article
          class="our-pick"
          style="background-image: url('/imgs/events/rumble/main_banner.jpeg')"
          v-for="n in 2"
          :key="n"
        >
          <div class="darken"></div>
          <span class="title">Rumble</span>
          <span class="date-span">JUNE 21</span>
        </article>
        <article
          class="our-pick"
          style="
            background-image: url('/imgs/events/circuit-s3/main_banner.jpeg');
          "
        >
          <div class="darken"></div>
          <span class="title">Circuit S3</span>
          <span class="date-span">08/21 - 11/21</span>
        </article>
      </div>
    </section>
    <section>
      <div class="section-head">
        <h2>Ongoing Events</h2>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <div class="content">
        <article class="event" v-for="n in 3" :key="n">
          <img src="/imgs/events/circuit-s3/logo.png" alt="" class="icon" />
          <div class="data">
            <span class="title">Circuit Season 3</span>
            <span class="date-span">Oct 28th - Oct 30th</span>
          </div>
        </article>
      </div>
    </section>
    <section>
      <div class="section-head">
        <h2>Finished events</h2>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <div class="content">
        <article class="finished-event" v-for="n in 3" :key="n">
          <img src="/imgs/events/circuit-s3/logo.png" alt="" class="icon" />
          <div class="data">
            <span class="title">Circuit Season 3</span>
            <span class="date-span">Oct 28th - Oct 30th</span>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {};
</script>