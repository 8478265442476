<template>
  <div>
    <div class="game-select" @click="overlay = true">
      <img :src="gameData.icon" :alt="gameData.name" />
      <span>{{ gameData.name }}</span>
      <i class="fa-solid fa-chevron-right"></i>
    </div>
    <transition name="fade">
      <div class="game-select-overlay" v-show="overlay">
        <div class="close">
          <i class="fa-solid fa-times" @click="overlay = false"></i>
        </div>
        <div class="games">
          <div
            class="game"
            v-for="g in $games"
            :key="g.name"
            @click="setGame(g.slug)"
          >
            <div
              class="game-icon"
              :style="`background-image: url('${g.icon}')`"
            ></div>
            <div class="selected" v-show="g.slug === game">
              <i class="fa-solid fa-check fa-xl"></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "game-select",
  data: () => ({
    overlay: false,
  }),
  computed: {
    gameData() {
      return this.$games.find((obj) => obj.slug === this.game);
    },
    game() {
      return this.$store.state.game;
    },
  },
  methods: {
    setGame(value) {
      this.$store.commit("setGame", value);
      this.overlay = false;
      this.$router.push({ params: { game: value } });
    },
  },
};
</script>

<style>
</style>