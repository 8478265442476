<template>
  <section class="page-header">
    <slot name="left">
      <button @click="$back">
        <i class="fa-solid fa-chevron-left fa-lg"></i>
      </button>
    </slot>
    <transition name="fade" mode="out-in">
      <span v-text="title" :key="title"></span>
    </transition>
    <slot name="right">
      <button></button>
    </slot>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  }
}
</script>