<template>
  <select class="l-select" @change="onChange" :class="{ grow, disabled }" :disabled="disabled">
    <option v-for="o in options" :value="o.value" :key="o.value" :selected="o.value === value" v-text="o.text"></option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    grow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    }
  }
}
</script>